// import logo from "./logo.svg";
import "./home.css";

function Home() {
  return (
    <div className="container">
      <div
        class="jumbotron"
        style={{
          marginBottom: "2rem",
          backgroundColor: "#e9ecef",
          padding: "4rem 2rem",
          borderRadius: "0.3rem",
        }}
      >
        <h1 class="display-4">Collect PiNs</h1>
        <p class="lead">
          PiNs (Picture Non-fungible tokens), a digital collectible, is being
          distributed in events and many outdoors. Parks in Northeast USA are
          workign towards publishing a QR code to give away NFTs during their
          interaction. Look for a nearby event or outdoor event for Clifty PiNs.
        </p>
        <hr class="my-4" />
        <p>
          Signup and start collecting PiNs. No credit card required. No crypto
          wallets. Completely FREE!
        </p>
        <a href="/signup" class="btn btn-primary">
          Free Signup
        </a>
      </div>
      <div class="row">
        <div class="col-sm-6" style={{ marginBottom: "2rem" }}>
          <div class="card">
            <div class="card-header">Events and public places</div>
            <div class="card-body">
              <h5 class="card-title">Collect PiNs</h5>
              <p class="card-text">
                Many organizations are offering their event attendees to collect
                NFT as part of their presence. Clifty makes it easy for you to
                just scan a QR and use email to start collecting NFTs.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6" style={{ marginBottom: "2rem" }}>
          <div class="card">
            <div class="card-header">Blockchain wallets</div>
            <div class="card-body">
              <h5 class="card-title">Custodial</h5>
              <p class="card-text">
                No need to understand how the technology works. No need to have
                any wallet like metamask. Just use your email and collect them
                with your phone with a camera to scan a QR code.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
