import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import Pagination from "react-bootstrap/Pagination";
import "./card.css";

const formatname = (name = "") => name.toLowerCase();
const formatDate = (datestring) => {
  const date = new Date(datestring);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

const orgsfields = [
  { fieldName: "id", headerName: "ID" },
  { fieldName: "name", headerName: "Name" },
  {
    fieldName: "created_at",
    headerName: "Signed up",
    formattedBy: formatDate,
  },
  { fieldName: "pinstotal", headerName: "Pins" },
  { fieldName: "tokensissued", headerName: "Tokens" },
  { fieldName: "tokensissuedbutnotcollected", headerName: "Recieved" },
];
const pin = [
  { fieldName: "loopindex", headerName: "ID" },
  { fieldName: "name", headerName: "Name" },
  { fieldName: "orgname", headerName: "Organization" },
  { fieldName: "maxtokens", headerName: "Tokens" },
  { fieldName: "accepted", headerName: "Received" },
];
const totalusers = [
  { fieldName: "id", headerName: "ID" },
  {
    fieldName: "email",
    headerName: "Email",
    // style: { textTransform: "lowercase !important" },
  },
  {
    fieldName: "created_at",
    headerName: "Signed up",
    formattedBy: formatDate,
  },
  {
    fieldName: "active",
    headerName: "Active",
    formattedBy: (active) => (active ? "Yes" : "No"),
  },
  { fieldName: "tokens", headerName: "Tokens" },
];
const dashboards = [
  {
    fieldName: "network",
    headerName: "Network",
    className: "text-capitalize",
    formattedBy: formatname,
  },
  { fieldName: "issued", headerName: "Tokens Issued" },
  { fieldName: "collected", headerName: "Tokens Collected" },
];
const activepins = [
  { fieldName: "name", headerName: "Name" },
  { fieldName: "active", headerName: "Active" },
  { fieldName: "evtype", headerName: "Evtype" },
  { fieldName: "orgname", headerName: "Orgname" },
  { fieldName: "maxtokens", headerName: "Maxtokens" },
  { fieldName: "accepted", headerName: "Accepted" },
];

function Card() {
  const { name } = useParams();
  const [field, setFields] = useState([]);
  const [data, setdata] = useState([]);
  // const firstupdate = useRef(true);

  const formatdata = (data, items, key) => {
    const newitems = data[key].reduce((acc, current, ind) => {
      const { mintnetwork, total } = current;
      acc.push({ ...items[ind], network: mintnetwork, [key]: total });
      return acc;
    }, []);
    return newitems;
  };
  useEffect(() => { }, [data]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(`${process.env.REACT_APP_BASEURL}/admin/${name}`, {
      headers: {
        Authorization: `Bearer ${user.sessionToken}`,
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (name === "dashboardCounts" && data.tokens) {
          let newdata = await formatdata(data.tokens, [], "issued");
          newdata = await formatdata(data.tokens, newdata, "collected");
          setdata(newdata);
        } else setdata(data);
      });
  }, [name]);

  useEffect(() => {
    if (name === "orgs") setFields(orgsfields);
    else if (name === "pins") setFields(pin);
    else if (name === "users") setFields(totalusers);
    else if (name === "dashboardCounts") setFields(dashboards);
    else if (name === "activepins") setFields(activepins);
  }, [name]);

  return (
    <div className="orgs">
      <h3 className="text-capitalize mb-5">
        {name}({data.length})
      </h3>
      <DynamicTable data={data} fields={field} />
    </div>
  );
}

export default Card;

const DynamicTable = ({ data, fields }) => {
  const [page, setpage] = useState(1);
  const lastindex = page * 10;
  const firstindex = lastindex - 10;
  const records = data.slice(firstindex, lastindex);

  const handlepagechange = (newpage) => setpage(newpage);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {fields.map((field, index) => {
            return <th key={index}>{field.headerName}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          records.map((item, index) => {
            return (
              <tr key={index}>
                {fields.map((field, ind) => {
                  if (field.fieldName === "loopindex")
                    return <td key={ind}>{index + 1}</td>;
                  return (
                    <td key={ind} className={field.className}>
                      {field.formattedBy
                        ? field.formattedBy(item[field.fieldName])
                        : item[field.fieldName]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
      <tfoot>
        <PaginationBasic
          active={page}
          totalpages={Math.ceil(data.length / 10)}
          handlepagechange={handlepagechange}
        ></PaginationBasic>
      </tfoot>
    </Table>
  );
};

const PaginationBasic = ({ active, totalpages, handlepagechange }) => {
  let items = [];
  for (let number = 1; number <= totalpages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => handlepagechange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
};
