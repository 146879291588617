import { useEffect, useState } from "react";
import CustomInput from "../customcomponent/custominput";
import "./signin.css";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";

function Signin({ setLogin, login, message }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [openlogin, setOpenlogin] = useState(false);
    useEffect(() => {
        setOpenlogin(!login);
    }, [login]);
    const navigate = useNavigate();

    const handlesignin = () => {
        fetch(`${process.env.REACT_APP_BASEURL}/admin/signin`, {
            method: "post",
            body: JSON.stringify({ email: username, password }),
            headers: { "content-type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data["error"]) {
                    setError(data["error"]);
                } else {
                    localStorage.setItem("user", JSON.stringify(data));
                    setLogin(true);
                    navigate("/dashboard");
                }
            });
    };
    const handleErrorpopup = () => {
        setError(null);
    };
    return (
        <div className="container content">
            {error && (
                <AlertPopup
                    Message={error}
                    variant="danger"
                    setShow={handleErrorpopup}
                />
            )}
            {openlogin && message && (
                <AlertPopup
                    Message={message}
                    variant="primary"
                    setShow={() => setOpenlogin(false)}></AlertPopup>
            )}
            <h1 className="mb-5">Signin</h1>
            <form className="sign">
                <CustomInput
                    label="Username"
                    name="Username"
                    value={username}
                    setValue={setUsername}
                />
                <CustomInput
                    label="Password"
                    type="password"
                    name="Password"
                    value={password}
                    setValue={setPassword}
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlesignin}>
                    SIGN IN
                </button>
            </form>
        </div>
    );
}

export default Signin;
