import { useState, useEffect } from "react";
import "./custominput.scss";

const Custominput = ({ label, name, id, value, setValue, type = "text" }) => {
  const [shrink, setShrink] = useState(false);
  useEffect(() => {
    setShrink(!!value.length);
  }, [value]);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };
  return (
    <div className="input-container">
      <label className={`${shrink ? "shrink" : ""}`} htmlFor={id}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        onChange={handleChange}
        onFocus={() => setShrink(true)}
      />
    </div>
  );
};

export default Custominput;
