import "./footer.css";

function Footer() {
  return (
    <footer className="">
      <div className="footer">
        <div className="name">
          <li>©2023</li>
          <li>
            <a href="#">Clifty Inc</a>
          </li>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
