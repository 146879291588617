import { Alert } from "react-bootstrap";

function AlertPopup({ Message, variant, setShow }) {
  return (
    <Alert variant={variant} onClose={() => setShow()} dismissible>
      <p>{Message}</p>
    </Alert>
  );
}
export default AlertPopup;
